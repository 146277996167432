import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import ScotlandYard from "../assets/img/scotlandyard.png";
import GOL from "../assets/img/gol.png";
import VHDL from "../assets/img/vhdl.png";
import AOC from "../assets/img/aoc.png";
import PE from "../assets/img/pe.png";
import LE from "../assets/img/le.png";
import HH from "../assets/img/hh.png";
import CBOD from "../assets/img/cbod.png";
import PAV from "../assets/img/PAV.png";
import AV from "../assets/img/AV.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import Reveal from "./Reveal";

export default function Projects() {
  const projects = [
    {
      title: "Java Scotland Yard Game",
      description:
        "Built upon skeleton code to develop both a game model and an Ai player. In our model we used core OOP conecpts like interfaces, encapsulation, overloading, the visitor design pattern and double dynamic dispath. Our AI used a Minimax approach, alpha-beta pruning, scoring functions and Dijkstras algorithm for distances",
      imgUrl: ScotlandYard,
      type: "University",
      date: "April 2023",
      link: "https://github.com/joshdfeather/ScotlandYard",
      report:
        "https://github.com/joshdfeather/ScotlandYard/blob/main/report.pdf",
    },
    {
      title: "Golang Game Of Life",
      description:
        "Built upon skeleton code to develop both distibuted and parallel solutions for the Game of Life. We implemented a concurrent solution for worker nodes using key concepts like multi-threading, mutexing and wait groups. Our distributed solution used an AWS broker node, prompted by an RPC call to hand work to worker AWS ndoes",
      imgUrl: GOL,
      type: "University",
      date: "November 2023",
      link: "https://github.com/joshdfeather/GameOfLife",
      report:
        "https://github.com/joshdfeather/GameOfLife/blob/main/report%20.pdf",
    },
    {
      title: "VHDL Peak Detector",
      description:
        "Programmed modular command and data processors to fit black box requirements. Used concepts surrounding VHDL like FSMs and components. Deployed code onto FPGA and communicated with port mapped gate array via a Putty terminal ",
      imgUrl: VHDL,
      type: "University",
      date: "March 2024",
      link: "https://github.com/joshdfeather/VHDL-Peak-Detector",
      report:
        "https://github.com/joshdfeather/VHDL-Peak-Detector/blob/main/Group_12_reports.pdf",
    },
    {
      title: "Advent of Code",
      description:
        "During 2022 completed 1st Star in levels 1-22, including 15 days with full 2 stars. During 2023 completed 5 days to 2 stars. Learnt extensively how to parse input data and break down logical problems",
      imgUrl: AOC,
      type: "Personal",
      date: "December 2022",
      link: "https://github.com/joshdfeather/Advent-of-Code-2022",
    },
    {
      title: "Health Hub 24hr Hackathon",
      description:
        "Produced a health focused web app ‘Health Hub’ in a team of 5, developing understanding of web development. Worked on Python based back end, using reverse engineered socket code, to read from bluetooth device",
      imgUrl: HH,
      type: "Personal",
      date: "March 2023",
      link: "https://github.com/joshdfeather/Health-Hub",
    },
    {
      title: "Project Euler",
      description:
        "Developed problem breakdown, with a growing focus on developing a repeatable approach to generating solutions. Many of these problems involve primes which required me learning key algorithmic methods like the Sieve of Erastosthenes",
      imgUrl: PE,
      type: "Personal",
      date: "October 2023",
      link: "https://github.com/joshdfeather/Project-Euler",
    },
    {
      title: "Leetcode",
      description:
        "Improved problem solving skills, code proficiency and analytical thinking by completing 50+ leetcode problems of ranging difficulty. During this period also aquired the Data Structures 1 Award with a focus on Trees, Hash Tables, Binary Trees and Searching Algorithms",
      imgUrl: LE,
      type: "Personal",
      date: "October 2023 - Present",
      link: "https://leetcode.com/u/joshdfeather/",
    },
    {
      title: "Cricket Object Detection",
      description:
        "Explored live object detection using Tensorflow orignally and later the specialised YOLOv8 model from Ultralytics. Learnt about dataset generation, annotation and partitioning; model training and fine tuning; as well as finally analytics and testing. Scraped data from pre-existing Roboflow Computer Vision Projects as well as exploring the Label Img annotation package",
      imgUrl: CBOD,
      type: "Personal",
      date: "August 2024",
      link: "https://github.com/joshdfeather/Cricket-Object-Detection",
    },
    {
      title: "Pathfinding Algorithm Visualizer",
      description:
        "This React-based algorithm visualizer shows Dijkstra's and A* pathfinding algorithms, by dynamically updating a grid to display node states (start, end, wall, visited, path) as algorithms run. It uses React’s state management for real-time updates, allowing users to interact with the grid by setting start/end nodes and walls. The app features drag-and-drop functionality for modifying the grid and provides metrics like path length, nodes visited, and runtime",
      imgUrl: PAV,
      type: "Personal",
      date: "August 2024",
      link: "https://joshdfeather.github.io/Algorithm-Visualizer/",
    },
    {
      title: "Open Source Contributions",
      description:
        "Began my open source contributing journey by implementing the A* greedy shortest path algorithm as part of the library for the algorithm-visualizer API. Built upon existing algorithmic design style and introduced the heuristic based approach in A*",
      imgUrl: AV,
      type: "Personal",
      date: "August 2024",
      link: "https://github.com/joshdfeather/algorithms",
      visualizer:
        "https://algorithm-visualizer.org/greedy/dijkstras-shortest-path",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <Reveal className="text-center">
              <h2>Projects</h2>
              <p>
                Below is a list of projects I have completed classed in two
                categories: University and Personal. Each project has an image
                summary, date of creation and a brief description. To view the
                GitHub repository/relevant page click on the corresponding
                panel.
              </p>
            </Reveal>
            <Reveal>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav
                  variant="pills"
                  className="nav-pills mb-5 justify-content-center align-items-center"
                  id="pills-tab"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">Personal</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">University</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row>
                      {projects.map((project, index) => {
                        if (project.type === "Personal")
                          return <ProjectCard key={index} {...project} />;
                      })}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row>
                      {projects.map((project, index) => {
                        if (project.type === "University")
                          return <ProjectCard key={index} {...project} />;
                      })}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Reveal>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
}
