import { motion } from "framer-motion";

// The Reveal component accepts children (content), and an optional className or custom variants.
const Reveal = ({ children, className = "", delay = 0 }) => {
  // Animation variants for the reveal effect
  const revealVariant = {
    hidden: { opacity: 0, y: 75 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.2 } },
  };

  return (
    <motion.div
      className={className}
      initial="hidden"
      whileInView="visible"
      variants={revealVariant}
    >
      {children}
    </motion.div>
  );
};

export default Reveal;
