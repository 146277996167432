import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/bbblurry.png";
import skills from "../assets/img/skills.avif";
import values from "../assets/img/core-values-related-icon-outline-and-linear-vector.jpg";

export default function Skills() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <img className="background-image-top-left" src={colorSharp} />
      <img className="background-image-top-right" src={colorSharp}></img>
      <img className="background-image-left" src={colorSharp} alt="Image" />
      <img className="background-image-right" src={colorSharp}></img>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills and Values</h2>
              <p>
                Here, you'll find a comprehensive summary of my key skills
                outside the programming domain, as well as an insight into my
                core values. I excel in{" "}
                <span style={{ color: "#8AFFE4" }}>Communication, </span>
                <span style={{ color: "#8AFFE4" }}>Leadership, </span>
                <span style={{ color: "#8AFFE4" }}>Teamwork, </span>
                <span style={{ color: "#8AFFE4" }}>Planning, </span>and
                <span style={{ color: "#8AFFE4" }}> Time Management. </span>
                Additionally, I bring a diverse set of professional
                proficiencies (including both{" "}
                <span style={{ color: "#8AFFE4" }}>MS Office</span> and
                <span style={{ color: "#8AFFE4" }}> Google </span>
                productivity suites). I have well developed
                <span style={{ color: "#8AFFE4" }}>
                  {" "}
                  Customer Service/Client Facing{" "}
                </span>
                skills and a strong background in both competitive and
                social/team
                <span style={{ color: "#8AFFE4" }}> Sports. </span>
                <span style={{ color: "#8AFFE4" }}>Community</span> is a huge
                part of all my life's facets but especially my time at Church.
                Many of my key values spring from my faith including
                <span style={{ color: "#8AFFE4" }}> Trust, </span>
                <span style={{ color: "#8AFFE4" }}> Generosity, </span>
                <span style={{ color: "#8AFFE4" }}> Forgiveness, </span> and
                <span style={{ color: "#8AFFE4" }}> Support. </span>
                Working hard for what I want is also core to me, including
                values like
                <span style={{ color: "#8AFFE4" }}> Success, </span>{" "}
                <span style={{ color: "#8AFFE4" }}> Goal Setting</span> and
                <span style={{ color: "#8AFFE4" }}> Motivation</span>
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Communication</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Leadership</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Teamwork</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Planning</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Time Management</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>Customer Service</h5>
                </div>
                <div className="item">
                  <div className="skills-wrapper">
                    <img src={skills} alt="Image" className="skills-values" />
                  </div>
                  <h5>MS Office/Google Suite</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Community</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Trust</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Generosity</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Forgiveness</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Support</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Success</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Goal Setting</h5>
                </div>
                <div className="item">
                  <div className="values-wrapper">
                    <img src={values} alt="Image" className="skills-values" />
                  </div>
                  <h5>Motivation</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
