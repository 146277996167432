import { Container, Row, Col } from "react-bootstrap";
import AWS from "../assets/img/aws.png";

export default function About() {
  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col size={12}>
            <h2>About This Project</h2>
            <p>
              This project was intended to double as both a Portfolio website
              and a project to highlight my skills using React, Bootstrap and
              AWS hosting using the Bootstrap CSS framework and hosting
            </p>
          </Col>
        </Row>
        <Col class="d-flex col-sm">
          <img className="aws-image" src={AWS}></img>
        </Col>
      </Container>
    </section>
  );
}
