import { Col } from "react-bootstrap";

export default function ProjectCard({
  title,
  description,
  imgUrl,
  type,
  date,
  link,
  report,
  visualizer,
}) {
  return (
    <Col size={12} sm={6} md={4}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
            <h5>
              <br />
              {date}
            </h5>
            {type === "University" && (
              <a
                href={report}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <button className="report">
                  <span>View Report</span>
                </button>
              </a>
            )}
            {type === "Personal" && visualizer && (
              <a
                href={visualizer}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <button className="report">
                  <span>View API Sandbox</span>
                </button>
              </a>
            )}
          </div>
        </div>
      </a>
    </Col>
  );
}
