import "react-multi-carousel/lib/styles.css";
import Reveal from "./Reveal"; // Import the Reveal component
import colorSharp from "../assets/img/bbblurry.png";
import video from "../assets/img/vecteezy_particles-background-4k_1623386.mp4";
import Heath from "../assets/img/heath_educational_school_book_specialists_lc.webp";
import Host from "../assets/img/host.png";
import MBDA from "../assets/img/mbda.jpg";
import Tutoring from "../assets/img/tutoring.png";

export default function Experience() {
  return (
    <section className="skill" id="experience">
      <img
        className="background-image-top-left"
        src={colorSharp}
        alt="Background"
      />
      <div className="container">
        <div className="video">
          <video autoPlay muted loop src={video} />
        </div>
        <div className="row">
          <div className="col-12">
            <Reveal className="d-flex justify-content-center">
              <h2>Experience</h2>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* MBDA Experience */}
          <div className="col-4">
            <Reveal className="skill-bx-2">
              <h3>MBDA</h3>
              <h4>Summer 2024</h4>
              <img src={MBDA} className="experience-img" alt="MBDA" />
              <p className="experience-txt">
                12 Week Summer Internship in Web Development with Software Dept
                <br />
                <br />- Expanded React JS experience, developing a full stack
                prototype for their local terminal
                <br />- Styled with Bootstrap + Tailwind CSS frameworks
                <br />- Explored Express + .NET servers and CRUD database
                fetching with SQLite
                <br />- Experience in corporate sector, with large code bases
                and company coding standards
              </p>
            </Reveal>
          </div>

          {/* Heath Books Experience */}
          <div className="col-4">
            <Reveal className="skill-bx-2">
              <h3>Heath Books</h3>
              <h4>Summer 2023</h4>
              <img src={Heath} className="experience-img" alt="Heath Books" />
              <p className="experience-txt">
                Logistics employee with Exports and Quality focus <br />
                <br />- Developed communication skills
                <br />- Task Management role
                <br />- Crucial importance of attention to detail
                <br />- MS office workflow
              </p>
            </Reveal>
          </div>

          {/* Host Staffing Experience */}
          <div className="col-4">
            <Reveal className="skill-bx-2">
              <h3>Host Staffing</h3>
              <h4>Summer 2023</h4>
              <img src={Host} className="experience-img" alt="Host Staffing" />
              <p className="experience-txt">
                Running and Plate Waiting shifts
                <br />
                <br />- Self presentation is paramount
                <br />- Experience in professional environment
                <br />- Developed client facing skills
              </p>
            </Reveal>
          </div>

          {/* Tutoring Experience */}
          <div className="col-4">
            <Reveal className="skill-bx-2">
              <h3>Tutoring</h3>
              <h4>Sep 2021 – Sep 2023</h4>
              <img src={Tutoring} className="experience-img" alt="Tutoring" />
              <p className="experience-txt">
                1 on 1 in-person and virtual tutoring, of GCSE Maths + Physics +
                Chemistry
                <br />
                <br />- Skilled explaining curriculum topics
                <br />- Identify key learning goals
                <br />- Adept at explaining topics in multiple ways
                <br />- Lead students to break down problems
              </p>
            </Reveal>
          </div>
        </div>
      </div>
      <img
        className="background-image-top-right"
        src={colorSharp}
        alt="Background"
      />
    </section>
  );
}
