import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/software-engineer.svg";
import video from "../assets/img/vecteezy_particles-background-4k_1623386.mp4";
import cv from "../assets/Software Engineer 2024 JDF CV.pdf";

export default function Banner() {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const toRotate = ["Software Engineer", "University Student"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <div className="video">
          <video autoPlay muted loop src={video} />
        </div>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to my Portfolio</span>
            <h1>{`Hi, I'm Josh`}</h1>
            <h1 style={{ color: "#8AFFE4" }}>
              <span
                className="txt-rotate"
                dataPeriod="1000"
                data-rotate='["Software Engineer", "University Student"]'
              >
                <span className="wrap">{text}</span>
              </span>
            </h1>
            <p>
              I am an undergraduate student, studying Computer Science and
              Electronics at the University of Bristol. Alongside my studies I
              am an avid programmer, specialising in React Web Development and
              Python Data Science
            </p>
            <a
              href={cv}
              download="Josh Featherstone CV"
              style={{ textDecoration: "none" }}
            >
              <button>
                Download CV PDF <ArrowRightCircle size={25} />
              </button>
            </a>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
