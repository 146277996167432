import { Container, Row, Col } from "react-bootstrap";

export default function Education() {
  return (
    <section className="education" id="education">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Education</h2>
            <h5>
              <br />
              <br />
              BRISTOL UNIVERSITY - BEng Computer Science and Electronics SEP
              2022 - Present
            </h5>
            <p>
              Year 2 <span style={{ color: "#8AFFE4" }}>(78% - First)</span>
              <br />
              Data Driven Computer Science; Computer Systems A; Computer Systems
              B; Algorithms II
              <br />
              Communications; Signals & Systems; Digital Systems, Electronics II
              <br />
              Digital Design Group Project; Principles of Professional Practice
              <br />
              <br />
              Year 1 <span style={{ color: "#8AFFE4" }}>(81% - First)</span>
              <br />
              Computer Architecture <br />
              Imperative & Functional Programming with coursework in C and
              Haskell
              <br />
              Object Oriented Programming & Algorithms with coursework in Java{" "}
              <br />
              Linear Circuits & Electronics, Engineering Mathematics, Discrete
              Mathematics <br />
            </p>
            <h5>
              TIFFIN SCHOOL - Kingston-Upon-Thames London SEPT 2020 - JUL 2022
            </h5>
            <p>
              A-Levels: <br />
              Maths <span style={{ color: "#8AFFE4" }}>(A*),</span> Further
              Maths <span style={{ color: "#8AFFE4" }}>(A*), </span> Physics
              <span style={{ color: "#8AFFE4" }}> (A*) </span> and Chemistry
              <span style={{ color: "#8AFFE4" }}> (A*)</span>
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
